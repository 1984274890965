(function (exports) {
  'use strict';

  const template$3 = document.createElement('template');
  template$3.innerHTML = `
      <style>

        input, option, select, label, p, div{
          font-family: sans-serif;
          font-size: 100%;
          line-height: 1.15;
          margin: 0;
        }

        #input_field_month{
          width:125px;
        }
        #input_field_day{
          width:60px;
        }
        #error_message{
          display:none;
          color:red;
        }
        #input_label{
          margin:0px;
        }
        .form-container div{
          display:inline-block;
          position:relative;
        }
        .form-container.error #error_message{
          display:block;
          height: 15px;
          position: relative;
          font-size: 80%;
          padding: 0px;
          margin: 0px;
          /* left: 10px; */
        }
        .form-container.error #input_label{
          color:red;
        }

        label{
          position: absolute;
          top: 28px;
          left: 10px;
          color: gray;
          font-size: 100%;
          transition: font-size .3s, color .3s, top .3s;
          pointer-events: none;
        }
        .form-container div:focus-within label{
          top:12px !important;
          font-size:80% !important;
          color:orange;
        }
        .form-container input:invalid + label,
        .form-container div.error label{
          top:28px;
          font-size:100%;
          color:red !important;
        }
        .form-container div.content label{
          top:12px !important;
          font-size:80% !important;
          color:gray;
        }
        /* .form-container:focus-within input:invalid + label{
          top:25px;
          font-size:80%;
          color:red !important;
        } */


        .form-container select:invalid,
        .form-container div.error select{
          outline: none !important;
          border-color: red !important;
          box-shadow: 0 0 5px red !important;
        }
        .form-container div:focus-within select{
          outline: none !important;
          border-color: #f47920;
          box-shadow: 0 0 5px #f47920;
        }

        select{
          border-radius: 4px;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 12px 4px;
          width: 80px;
          max-width: 90%;
          padding-top:20px;
          border: 1px solid #ccc;
        }

      </style>
      <div class="form-container">
        <!-- <input type="email" id="input_field"> -->
        <p id="input_label" for="input_field">Date of Birth</p>
        <div>
          <select id="input_field_month">
            <option value='empty'></option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <label id="input_label_month" for="input_field_month">Month</label>
        </div>
        <div>
          <select id="input_field_day"><option value='empty'></option></select>
          <label id="input_label_day" for="input_field_day">Day</label>
        </div>
        <div>
          <select id="input_field_year"><option value='empty'></option></select>
          <label id="input_label_year" for="input_field_year">Year</label>
        </div>

        <p id="error_message"></p>
        <em style="color:#aaa; display:block;font-size:.75em; position:relative;">Your age will not be displayed publically</em>
      </div>`;
  class DobInput extends HTMLElement {
    constructor() {
      super();
      const templateContent = template$3.content;
      this.attachShadow({mode: 'open'})
          .appendChild(templateContent.cloneNode(true));
    }
    static get observedAttributes() {
      return [
        'id',
        'login',
      ];
    }
    attributeChangedCallback(name, oldValue, newValue) {
      this.updateStyle();
    }
    connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      const daySelector = this.shadowRoot.querySelector('#input_field_day');
      for (let day = 1; day <= 31; day++) {
        const dayOpt = document.createElement('option');
        daySelector.appendChild(dayOpt);
        dayOpt.outerHTML = `<option value=${day.length > 1 ? day : `0${day}`}>${day}</option>`;
      }
      const yearSelector = this.shadowRoot.querySelector('#input_field_year');
      for (let year = new Date(Date.now()).getFullYear(); year >= '1900'; year--) {
        const yearOpt = document.createElement('option');
        yearSelector.appendChild(yearOpt);
        yearOpt.outerHTML = `<option value=${year}>${year}</option>`;
      }
      const selectChecker = event => {
        if (event.target.value != 'empty') {
          event.target.parentNode.classList.remove('error');
          event.target.parentNode.classList.add('content');
        } else {
          event.target.parentNode.classList.add('error');
          event.target.parentNode.classList.remove('content');
        }
        this.updateStyle();
      };
      const dobChecker = event => {
        const dobCheck = this.checkValidity();
        if (dobCheck.isValid && dobCheck.olderThan13) {
          this.shadowRoot.querySelector('.form-container').classList.remove('error');
          this.updateStyle();
          this.onchange();
          return;
        } else if (dobCheck.isValid && !dobCheck.olderThan13) {
          this.shadowRoot.querySelector('#error_message').innerHTML = 'Must be at least 13 years old';
          this.shadowRoot.querySelector('.form-container').classList.add('error');
          this.updateStyle();
          this.onchange();
          return;
        }
        this.shadowRoot.querySelector('.form-container').classList.add('error');
        if (this.shadowRoot.querySelector('#input_field_month').value == 'empty') {
          this.shadowRoot.querySelector('#error_message').innerHTML = 'Month of Birth required';
          this.shadowRoot.querySelector('#input_field_month').parentNode.classList.add('error');
        } else {
          this.shadowRoot.querySelector('#input_field_month').parentNode.classList.remove('error');
        }
        if (this.shadowRoot.querySelector('#input_field_day').value == 'empty') {
          this.shadowRoot.querySelector('#error_message').innerHTML = 'Day of Birth required';
          this.shadowRoot.querySelector('#input_field_day').parentNode.classList.add('error');
        } else {
          this.shadowRoot.querySelector('#input_field_day').parentNode.classList.remove('error');
        }
        if (this.shadowRoot.querySelector('#input_field_year').value == 'empty') {
          this.shadowRoot.querySelector('#error_message').innerHTML = 'Year of Birth required';
          this.shadowRoot.querySelector('#input_field_year').parentNode.classList.add('error');
        } else {
          this.shadowRoot.querySelector('#input_field_year').parentNode.classList.remove('error');
        }
        this.updateStyle();
        this.onchange();
      };
      const inputChangeChecker = event => {
        dobChecker();
        selectChecker(event);
      };
      this.shadowRoot.querySelector('#input_field_month').onchange = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_month').onblur = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_month').onclick = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_month').onfocusout = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_day').onchange = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_day').onblur = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_day').onclick = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_day').onfocusout = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_year').onchange = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_year').onblur = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_year').onclick = inputChangeChecker;
      this.shadowRoot.querySelector('#input_field_year').onfocusout = inputChangeChecker;
      this.updateStyle();
    }
    updateStyle() {
      if (this.shadowRoot.querySelector('#input_field_month').value != 'empty') {
        this.shadowRoot.querySelector('#input_field_month').parentNode.classList.add('content');
      } else {
        this.shadowRoot.querySelector('#input_field_month').parentNode.classList.remove('content');
      }
      if (this.shadowRoot.querySelector('#input_field_day').value != 'empty') {
        this.shadowRoot.querySelector('#input_field_day').parentNode.classList.add('content');
      } else {
        this.shadowRoot.querySelector('#input_field_day').parentNode.classList.remove('content');
      }
      if (this.shadowRoot.querySelector('#input_field_year').value != 'empty') {
        this.shadowRoot.querySelector('#input_field_year').parentNode.classList.add('content');
      } else {
        this.shadowRoot.querySelector('#input_field_year').parentNode.classList.remove('content');
      }
    }
    injectStyleSheet(styleURL) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(`@import url("${styleURL}");`));
      this.shadowRoot.appendChild(style);
    }
    checkValidity() {
      this.valid = false;
      this.olderThan13 = false;
      const month = this.shadowRoot.querySelector('#input_field_month').value;
      const day = this.shadowRoot.querySelector('#input_field_day').value;
      const year = this.shadowRoot.querySelector('#input_field_year').value;
      if (month == 'empty' || day == 'empty' || year == 'empty') {
        return {
          isValid: false,
        };
      }
      this.valid = true;
      let olderThan13 = false;
      const bdayts = new Date(`${month }/${ day }/${ year}`).getTime();
      const thirteenYearsAgo = Date.now() - 13 * 365 * 24 * 60 * 60 * 1000;
      if ( bdayts < thirteenYearsAgo ) {
        olderThan13 = true;
        this.olderThan13 = true;
      }
      this.value = `${year }-${ month }-${ day}`;
      return {
        isValid: this.valid,
        olderThan13,
      };
    }
    onchange() {}
  }
  customElements.define('dob-input', DobInput);

  const template$2 = document.createElement('template');
  template$2.innerHTML = `
      <style>

        input, option, select, label, p, div{
          font-family: sans-serif;
          font-size: 100%;
          line-height: 1.15;
          margin: 0;
        }

        .form-container{
          position:relative;
        }

        #error_message{
          display:none;
          color:red;
        }
        .form-container.error #error_message{
          display:block;
          height: 15px;
          position: relative;
          font-size: 80%;
          padding: 0px;
          margin: 0px;
          /* left: 10px; */
        }

        label{
          position: absolute;
          top: 28px;
          left: 12px;
          color: gray;
          font-size: 100%;
          transition: font-size .3s, color .3s, top .3s;
        }
        .form-container:focus-within label{
          top:17px !important;
          font-size:80% !important;
          color:orange;
        }
        .form-container input:invalid + label,
        .form-container.error label{
          top:28px;
          font-size:100%;
          color:red !important;
        }
        .form-container.content label{
          top:17px !important;
          font-size:80% !important;
          color:gray;
        }
        .form-container div{
          display:inline-block;
          position:relative;
        }
        /* .form-container:focus-within input:invalid + label{
          top:17px;
          font-size:80%;
          color:red !important;
        } */


        .form-container input:invalid,
        .form-container.error input{
          outline: none !important;
          border-color: red !important;
          box-shadow: 0 0 5px red !important;
        }
        .form-container:focus-within input{
          outline: none !important;
          border-color: #f47920;
          box-shadow: 0 0 5px #f47920;
        }

        input[type=email] {
          border-radius: 4px;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 12px 10px;
          width: 250px;
          max-width: 90%;
          padding-top:20px;
          border: 1px solid #ccc;
        }
      </style>
      <div class="form-container">
        <div>

          <input type="email" id="input_field">
          <label id="input_label" for="input_field">Email</label>
          <p id="error_message"></p>
      </div>
      </div>`;
  template$2.addEventListener('load', event => {
    console.log('template loaded');
  });
  class EmailInput extends HTMLElement {
    constructor() {
      super();
      this.attachShadow({mode: 'open'});
      this.shadowRoot.appendChild(template$2.content.cloneNode(true));
    }
    static get observedAttributes() {
      return [
        'id',
        'login',
      ];
    }
    attributeChangedCallback(name, oldValue, newValue) {
      this.updateStyle();
    }
    connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      const emailChecker = () => {
        if (this.checkValidity() && this.shadowRoot.querySelector('#input_field').value.length > 0) {
          this.shadowRoot.querySelector('.form-container').classList.remove('error');
          this.valid = true;
        } else if (this.shadowRoot.querySelector('#input_field').value.length > 0) {
          this.shadowRoot.querySelector('#error_message').innerHTML = 'Invalid email detected';
          this.shadowRoot.querySelector('.form-container').classList.add('error');
          this.valid = false;
        } else {
          this.shadowRoot.querySelector('#error_message').innerHTML = 'Email required';
          this.shadowRoot.querySelector('.form-container').classList.add('error');
          this.valid = false;
        }
        this.checkValidity();
        this.updateStyle();
        this.onchange();
      };
      this.shadowRoot.querySelector('#input_field').onkeyup = emailChecker;
      this.shadowRoot.querySelector('#input_field').onblur = emailChecker;
      this.updateStyle();
    }
    updateStyle() {
      if (this.shadowRoot.querySelector('#input_field').value) {
        this.shadowRoot.querySelector('.form-container').classList.add('content');
      } else {
        this.shadowRoot.querySelector('.form-container').classList.remove('content');
      }
    }
    injectStyleSheet(styleURL) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(`@import url("${styleURL}");`));
      this.shadowRoot.appendChild(style);
    }
    setError(errorMsg) {
      this.shadowRoot.querySelector('#error_message').innerHTML = errorMsg;
      this.shadowRoot.querySelector('.form-container').classList.add('error');
    }
    checkValidity() {
      this.value = this.shadowRoot.querySelector('#input_field').value;
      if (this.shadowRoot.querySelector('#input_field:valid')) {
        return true;
      }
      return false;
    }
    onchange() {}
  }
  customElements.define('email-input', EmailInput);

  const template$1 = document.createElement('template');
  template$1.innerHTML = `
      <style>

        input, option, select, label, p, div{
          font-family: sans-serif;
          font-size: 100%;
          line-height: 1.15;
          margin: 0;
        }

        #password_error_message{
          display:none;
          color:red;
        }
        .form-container.error #password_error_message{
          display:block;
          height: 15px;
          position: relative;
          font-size: 80%;
          padding: 0px;
          margin: 0px;
          /* left: 10px; */
        }

        label{
          position: absolute;
          top: 28px;
          left: 12px;
          color: gray;
          font-size: 100%;
          transition: font-size .3s, color .3s, top .3s;
        }
        .form-container:focus-within label{
          top:25px;
          font-size:80%;
          color:orange;
        }
        .form-container.error label{
          top:28px;
          font-size:100%;
          color:red !important;
        }
        .form-container.error:focus-within label{
          top:18px;
          font-size:80%;
          color:red !important;
        }
        .form-container.content label{
          top:18px;
          font-size:80%;
          color:gray;
        }

        .form-container.error input{
          outline: none !important;
          border-color: red !important;
          box-shadow: 0 0 5px red !important;
        }
        .form-container:focus-within input{
          outline: none !important;
          border-color: #f47920;
          box-shadow: 0 0 5px #f47920;
        }

        .form-container div.password-parent-container{
          display:inline-block;
          position:relative;
        }

        input[type=password] {
          border-radius: 4px;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 12px 10px;
          width: 250px;
          max-width: 90%;
          padding-top:20px;
          border: 1px solid #ccc;
        }

        .form-container.showPasswrodStrength:focus-within #passContainer{
          display:block;
        }
        .form-container.showPasswrodStrength.lock #passContainer{
          display:block;
        }

        #passContainer{
          /* margin: 0 auto; */
          text-align: left;
          display: none;
          width: 250px;
          max-width:90%;
        }

        #passStrBorder{
          width: 100%;
          border: 1px solid #AAAAAA;
          border-radius: 2px;
          display: block;
          overflow: hidden;
          height: 8px;
        }

        #passStrBar{
          display: block;
          height: 8px;
          position: relative;
          transition: width .3s;
          /* background-color: #f47920; */
        }

        #passStrWord{
          /* color: black; */
          position: relative;
          display: inline-block;
          width: 80px;
        }
        .tooShort {
          color:#F11D28;
        }
        .weak {
          color:#F11D28;
        }
        .medium {
          color:#FE612C;
        }
        .strong {
          color: #f47920;
        }

        #passStrBar.tooShort {
          width: 10%;
          width: calc(250px * 0.1);
          background-color: #F11D28;
        }
        #passStrBar.weak {
          width: 25%;
          width: calc(250px * 0.25);
          background-color: #F11D28;
        }
        #passStrBar.medium {
          width: 66%;
          width: calc(250px * 0.66);
          background-color: #FE612C;
        }
        #passStrBar.strong {
          width:100%;
          width: 250px;
          background-color: #f47920;
        }

      </style>
      <div class="form-container">
        <div class="password-parent-container">
        <label id="input_label" for="input_field">Password</label>
        <input type="password" id="input_field">
        <p id="password_error_message"></p>
        <div id="passContainer">
            Password strength:
            <div id="passStrWord"></div>
            <span id="passStrBorder">
                <span id="passStrBar"></span>
            </span>
        </div>
        </div>
      </div>`;
  class PasswordInput extends HTMLElement {
    constructor() {
      super();
      const templateContent = template$1.content;
      this.attachShadow({mode: 'open'})
          .appendChild(templateContent.cloneNode(true));
    }
    static get observedAttributes() {
      return [
        'id',
        'showPasswrodStrength',
      ];
    }
    attributeChangedCallback(name, oldValue, newValue) {
      this.updateStyle();
    }
    connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      const passwordChecker = () => {
        if (this.getAttribute('showPasswrodStrength')) {
          this.shadowRoot.querySelector('.form-container').classList.add('lock');
        } else {
          this.shadowRoot.querySelector('.form-container').classList.remove('lock');
        }
        if (this.shadowRoot.querySelector('#input_field').value.length > 2) {
          this.shadowRoot.querySelector('.form-container').classList.remove('error');
          this.valid = true;
        } else if (this.shadowRoot.querySelector('#input_field').value.length > 0) {
          this.shadowRoot.querySelector('#password_error_message').innerHTML = 'Password must be at least 3 characters';
          this.shadowRoot.querySelector('.form-container').classList.add('error');
          this.valid = false;
        } else {
          this.shadowRoot.querySelector('#password_error_message').innerHTML = 'Password required';
          this.shadowRoot.querySelector('.form-container').classList.add('error');
          this.valid = false;
        }
        this.updateStyle();
        this.value = this.shadowRoot.querySelector('#input_field').value;
        this.onchange();
      };
      this.shadowRoot.querySelector('#input_field').onkeyup = passwordChecker;
      this.shadowRoot.querySelector('#input_field').onblur = passwordChecker;
      this.updateStyle();
    }
    updateStyle() {
      if (this.shadowRoot.querySelector('#input_field').value) {
        this.shadowRoot.querySelector('.form-container').classList.add('content');
      } else {
        this.shadowRoot.querySelector('.form-container').classList.remove('content');
      }
      if (this.getAttribute('showPasswrodStrength')) {
        this.shadowRoot.querySelector('.form-container').classList.add('showPasswrodStrength');
      }
      if (this.shadowRoot.querySelector('#input_field').value.length > 0 && this.getAttribute('showPasswrodStrength')) {
        this.passwordStrength(this.shadowRoot.querySelector('#input_field').value);
      }
    }
    injectStyleSheet(styleURL) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(`@import url("${styleURL}");`));
      this.shadowRoot.appendChild(style);
    }
    setError(errorMsg) {
      this.shadowRoot.querySelector('#password_error_message').innerHTML = errorMsg;
      this.shadowRoot.querySelector('.form-container').classList.add('error');
    }
    passwordStrength(password) {
      const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
      const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
      let msg = 'Too Short';
      let className = 'tooShort';
      if (strongPassword.test(password)) {
        msg = 'Strong';
        className = 'strong';
      } else if (mediumPassword.test(password)) {
        msg = 'Medium';
        className = 'medium';
      } else if (password.length > 2) {
        msg = 'Weak';
        className = 'weak';
      }
      this.shadowRoot.querySelector('#passStrWord').innerHTML = msg;
      this.shadowRoot.querySelector('#passStrWord').className = className;
      this.shadowRoot.querySelector('#passStrBar').className = className;
    }
    onchange() {
      return true;
    }
  }
  customElements.define('password-input', PasswordInput);

  const template = document.createElement('template');
  template.innerHTML = `
  <style>

    .g-signin2{
      /* width: 100%; */
      display: inline-block;
      text-align: center;
      /* margin-left: 42px; */
      margin-bottom: 15px;
    }

    .g-signin2 > div{
      width: 245px !important;
    }
    .g-signin2 svg{
      top: inherit;
    }

    #login_captcha_element > div,
    #signup_captcha_element > div,
    #captcha_element_password > div,
    #captcha_element > div{
      width: inherit !important;
    }

    img.profilePreview{
      height: 150px;
      width: 150px;
    }

    #td-auth-container #logo{
      max-width: 90%;
      margin-left: 5%;
      margin-bottom: 20px;
      display: block;
    }

    #talkdust_dialog.auth-dialog > h2{
      display:none;
    }

    button#noBtn,
    button.backBtn{
        /* width: 200px; */
        background-color:inherit;
        border-color:inherit;
        color:#f47920;
        border: 0px;
        color: #ccc;

    }
    button#noBtn:hover,
    button.backBtn:hover{
        text-decoration: underline;
        background-color:inherit;
    }

    button#resendIt,
    button#haveIt{
      color: #2d3222;
      background-color: initial;
      border-color: #ccc;
      border-radius: 3px;
    }

    input#searchBar{
        margin-right: inherit;
    }

    input[type=text]{
        border-radius: 4px;
    /*    margin-top: 15px;*/
        padding: 12px 10px;
        width: 250px;
        max-width:90%;
    }

    input[type=password]{
        border-radius: 4px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 12px 10px;
        width: 250px;
        max-width:90%;
    }

    button{
        background-color: #f47920;
        color: white;
        border-style: solid;
        border-radius: 5px;
        border-width: 2px;
        border-color: #f47920;
        padding: 5px 10px;
        width: 250px;
        max-width:90%;
        cursor: pointer;
        font-weight: bold;
        margin:5px;
        height: 38px;
        transition: color 0.25s 0.0833333333s;
        transition: background-color 0.25s 0.0833333333s;
        position:relative;

    }

    .primary{
      --angle: 0deg;
      /* … */
      border-image: linear-gradient(var(--angle), #fe612c, #d53a9d) 1;
      border-width:5px;
      animation: 10s rotate linear infinite;
    }

    button:hover{
        background-color: white;
        background-color: rgba(255,255,255,.5);
        color: #f47920;
    }

    button.primary:disabled,
    button:disabled{
      background-color:gray;
      border-color:gray;
      color:white;
      color: lightgray;
    }

    button:disabled:hover{
      background-color:gray;
      border-color:gray;
      color:white;
      cursor: not-allowed;
    }

    @keyframes rotate {
      to {
        --angle: 360deg;
      }
    }

    @property --angle {
      syntax: '<angle>';
      initial-value: 0deg;
      inherits: false;
    }

    div > a{
        text-decoration: none;
        color: #aaa;
    }

    div > a:hover{
        text-decoration: underline;
        color: #f47920;
        cursor:pointer;
    }

    #termsAndAgreement{
        margin-right: auto;
    }

    #td-auth-container{
        border-radius: 10px;
        text-align: center;
        border-radius: 10px;
        background-color: rgba(255,255,255,.7);
        padding: 20px 0px;
        /* margin-top: 10px; */
        margin-top: 5vh;
        width: 90%;
        margin-left: 5%;
    }

    #create-container{
      /* border-radius: 10px; */
        text-align: center;
        /* border-radius: 10px; */
        /* background-color: rgba(255,255,255,.7); */
        padding: 20px 0px;
        margin-top: 10px;
        /* width: 90%; */
        /* margin-left: 5%; */
    }

    #activation{
        border-radius: 10px;
        text-align: center;
        border-radius: 10px;
        background-color: rgba(255,255,255,.7);
        padding: 20px 0px;
        margin-top: 10px;
        width: 90%;
        margin-left: 5%;
    }

    #activation h2{
        color:#f47920;
    }

    .show{
        display:block;
    }

    .hide{
        display:none;
    }

    #td-auth-container ul{
      padding: 0px;
    }
    #td-auth-container ul li{
      display: inline-block;
      font-size: 1.25em;
      transition: font-size .6s, background .6s;
      background: darkgray;
      color: lightgray;
      padding: 4px;
    }
    #td-auth-container ul li.active{
      display:inline-block;
      font-size: 1.5em;
      background: #f47920;
      color:white;
    }

    #td-auth-container #sign-up-container,
    #td-auth-container #login-container,
    #td-auth-container #prompt-container,
    #td-auth-container #validate-container,
    #td-auth-container #error-container{
      display:none;
    }

    auth-component[data-state='login'] #td-auth-container #login-container{
      display:block;
    }
    auth-component[data-state='signup'] #td-auth-container #sign-up-container{
      display:block;
    }

    auth-component[data-state='prompt'] #td-auth-container #prompt-container{
      display:block;
    }

    auth-component[data-state='validation'] #td-auth-container #validate-container{
      display:block;
    }

    auth-component[data-state='error'] #td-auth-container #error-container{
      display:block;
    }

    .otc {
      position: relative;
      width: 320px;
      margin: 0 auto;
    }

    .otc fieldset {
      border: 0;
      padding: 0;
      margin: 0;
    }

    .otc fieldset div {
      display: flex;
      align-items: center;
    }

    .otc legend {
      margin: 0 auto 1em;
      color: #5555FF;
    }

    input[type="number"] {
      width: .82em;
      line-height: 1;
      margin: .1em;
      padding: 8px 0 4px;
      font-size: 2.65em;
      text-align: center;
      appearance: textfield;
      -webkit-appearance: textfield;
      border: 2px solid #FF8C00;
      color: LightSlateGrey;
      border-radius: 4px;
    }

    input[type="date"] {
      /* border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 12px 10px;
      width: 250px;
      max-width:90%;
      color: gray; */
      border-style: solid;
      border-width: 1px;
      border-color: lightgray;
      /* padding: 5px 10px; */
      /* width: 250px; */
      /* max-width:90%; */
      cursor: pointer;
      /* margin:5px; */
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 12px 10px;
      width: 250px;
      max-width: 90%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* 2 group of 3 items */
    input[type="number"]:nth-child(n+4) {
      order: 2;
    }
    .otc div::before {
      content: '';
      height: 2px;
      width: 24px;
      margin: 0 .25em;
      order: 1;
      background: #FF8C00;
    }

    /* From: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034 */
    .otc label {
      border: 0 !important;
      clip: rect(1px, 1px, 1px, 1px) !important;
      -webkit-clip-path: inset(50%) !important;
      clip-path: inset(50%) !important;
      height: 1px !important;
      margin: -1px !important;
      overflow: hidden !important;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important;
      white-space: nowrap !important;
    }

    .dropbtn {
      width: .82em;
      line-height: 1;
      margin: .1em;
      padding: 8px 0 4px;
      font-size: 2.65em;
      text-align: center;
      appearance: textfield;
      -webkit-appearance: textfield;
      border: 2px solid #FF8C00;
      color: LightSlateGrey;
      border-radius: 4px;
      font-size: small;
      position: relative;
      margin-top: -60px;
      bottom: inherit;
      top: inherit;
      top: -8px;
      left: 9px
    }

    .dropdown {
      position: relative;
      bottom: inherit;
      top: inherit;
      top: -8px;
      left: 9px;
      height: 30;
    }

    .dropdown-content {
      display: none;
      position: relative;
      background-color: #f1f1f1;
      min-width: 160px;
      overflow: auto;
      border: 2px solid #f47920;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
    }

    .dobBox {
      border: 2px solid #708090;
      padding: 8px 16px;
      border: 1px solid transparent;
      border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
      cursor: pointer;
      user-select: none;
    }

    .dobDay {
      height: 25px;
      width: 25px;
      border: 2px solid #f47920;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
    }

    .dobYear {
      height: 25px;
      width: 75px;
      border: 2px solid #f47920;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
    }

    .dropdown a:hover {background-color: #ddd;}

    #prompt-options-container{
      display: inline-block;
    }

    @media (min-width:400px){
        #td-auth-container{
        /* , #login-container, #sign-up-container, #activation{ */
            /* max-width:330px; */
            margin-right: auto;
            margin-left: auto;
            /* box-shadow: 0px 0px 2px #312e2e; */
        }

        #create-container{
          /* max-width:330px; */
          margin-right: auto;
          margin-left: auto;
          /* box-shadow: 0px 0px 2px #312e2e; */
        }
    }
    @media (min-width: 880px){
        #td-auth-container{

        /* }, #login-container, #sign-up-container, #activation { */
            max-width:660px;
        }

        #create-container{
            max-width:660px;
        }

        /* .g-signin2{
          margin-left: 207px;
        } */

        label{
            width: 150px;
            display: inline-block;
            text-align: right;
        }
        input{
            margin-right: 150px;
        }
        button{
            width:auto;
            height:40px;
            margin-top:15px;
        }
    }
  </style>


  <div id="td-auth-container">

    <img id="logo" src="/img/TalkdustbannerSuperRes.png" alt="TalkDust" onclick="window.location.href=&quot;/&quot;">

    <div id="sign-up-container">
      <h3 style="color: #aaa" id="signup-header">
        Join the Conversation
      </h3>

      <div id="signup-dynamic-content"></div>
      <div id="inner-signup-container">
          <div id="continue-with-email-container">
            <button id="submit">Sign up with email</button>
          </div>

          <div id="oauth2-container">
            <h2 style="color: #aaa; position:relative;">
              <div style="border-top: 1px solid #aaa; width: 25%; position:absolute;top:15px;left:15%;"></div>
              or
              <div style="border-top: 1px solid #aaa; width: 25%; position:absolute;top:15px;right:15%;"></div>
            </h2>
            <span id="alternate-login-container">
              <!-- This already is present down below -->
            <i style="color:#555;display:none;">Have an account or sign in with another platform?</i>
            <a style="display:none;" href="/stage/login.html"> Log in</a>
            <!--Login up-->
            <div id="g_id_onload" class="g-signin2" data-onsuccess="onSignIn" data-context="signup"></div>
            <br>
            <div onlogin="facebookLoginCallbackSignup()"
                 class="fb-login-button"
                 style="min-height: 40px;"
                 data-scope="email"
                 data-max-rows="1"
                 data-size="large"
                 data-button-type="continue_with"
                 data-show-faces="true"
                 data-auto-logout-link="false"
                 data-use-continue-as="true">
            </div>
            </span>
          </div>

          <div id="sign-up-form-container" style="display:none;">
            <email-input></email-input>
            <password-input showPasswrodStrength='true'></password-input>
            <dob-input></dob-input>

            <div id="signup_captcha_element"></div>

            <div id="checkBoxWrapper" class="hide" style="font-size: .75em;padding-top: 5px;max-width: 90%;margin-left: 5%;">
              By continuing, you are agreeing to our <a href="/stage/terms-and-privacy.html">terms and conditions.</a>
            </div>

            <button id="continue" disabled>Continue</button>
          </div>
        </div> <!-- End of Innersignup container -->

        <div id="create-container">
          Have an account? <a href="#" >Login</a>
        </div>
    </div>

    <div id="login-container">

        <!-- <h2 style="color: #aaa" id="test">Sign up to talk in the moment interests with people all over the world</h2> -->
        <h3 id="login-messages" style="color:#aaa"></h3>
        <div id="login-dynamic-content"></div>
        <div id="captcha-container" style="display:none;">
          <div id="login_captcha_element"></div>
        </div>
        <div id="login-options-container">
          <p id="error-display" class="hide"></p>
          <email-input></email-input>
          <password-input></password-input>
          <button id="login" disabled>Log In</button>
          <br><br>
          <a id="forgotPassword">Password Issues?</a>

          <h2 style="color: #aaa; position:relative;">
            <div style="border-top: 1px solid #aaa; width: 25%; position:absolute;top:15px;left:15%;"></div>
            or
            <div style="border-top: 1px solid #aaa; width: 25%; position:absolute;top:15px;right:15%;"></div>
          </h2>

          <!--Login up-->
          <div class="g-signin2" data-onsuccess="onSignIn"></div>
          <br>
          <div onlogin="facebookLoginCallback()"
               class="fb-login-button"
               style="min-height: 40px;"
               data-scope="email"
               data-max-rows="1"
               data-size="large"
               data-button-type="continue_with"
               data-show-faces="true"
               data-auto-logout-link="false"
               data-use-continue-as="true">
          </div>
        </div>

        <div id="create-container">
          Need an account? <a href="#" >Sign up</a>
        </div>
    </div>

    <div id="prompt-container">
      <h3 style="color: #aaa" id="prompt-header">
        Account needs activation
      </h3>
      <div id="prompt-content"></div>
      <button id='noBtn'>Cancel</button>
      <div id="prompt-options-container">

      </div>
    </div>

    <div id="validate-container">
      <h3 style="color: #aaa" id="validation-header">
        Step 2 of 2 - Enter 6-digit code
      </h3>
      <input id="num1" class="digitEntry" type='number' pattern='[0-9]*' min='0' max='0' maxlength='1' inputtype='numeric' required />
      <input id="num2" class="digitEntry" type='number' pattern='[0-9]*' min='0' max='0' maxlength='1' inputtype='numeric' required />
      <input id="num3" class="digitEntry" type='number' pattern='[0-9]*' min='0' max='0' maxlength='1' inputtype='numeric' required />
      <input id="num4" class="digitEntry" type='number' pattern='[0-9]*' min='0' max='0' maxlength='1' inputtype='numeric' required />
      <input id="num5" class="digitEntry" type='number' pattern='[0-9]*' min='0' max='0' maxlength='1' inputtype='numeric' required />
      <input id="num6" class="digitEntry" type='number' pattern='[0-9]*' min='0' max='0' maxlength='1' inputtype='numeric' required />
      <br>
      <button class='backBtn'>Back</button>
      <button id='okBtn' disabled>Validate</button>
    </div>

    <div id="error-container">
      <h3 style="color: #aaa" id="error-header">
        Error
      </h3>
      <div id="error-content"></div>
      <button id='errBackBtn'>Back</button>
      <!-- <div id="prompt-options-container">

      </div> -->
    </div>
  </div>
`;
  const greEle = document.createElement('script');
  greEle.type = 'text/javascript';
  greEle.async = true;
  greEle.defer = true;
  greEle.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(greEle, firstScript);
  class AuthElement extends HTMLElement {
    constructor() {
      super();
      this.AUTH_OBJ = {};
      this.previousState = 'login';
    }
    static get observedAttributes() {
      return [
        'id',
        'data-closed',
        'data-custom-title',
        'data-custom-icon',
        'data-custom-enabled',
        'data-state',
      ];
    }
    attributeChangedCallback(name, oldValue, newValue) {
      switch (name) {
        case 'id':
          break;
        case 'data-closed':
        case 'data-custom-icon':
        case 'data-custom-enabled':
        case 'data-custom-title':
          break;
        case 'data-state':
          if (this.state === 'login' || this.state === 'signup') {
            this.previousState = this.state || newValue;
          }
          this.state = newValue;
          break;
      }
      this.updateStyle();
    }
    connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      const templateContent = template.content;
      if (this.querySelector('#td-auth-container') === null) {
        this.appendChild(templateContent.cloneNode(true));
      }
      const loginFormChecker = () => {
        const email = this.querySelector('#login-container email-input').valid || false;
        const password = this.querySelector('#login-container password-input').valid || false;
        if (email && password) {
          this.querySelector('#login-container #login').removeAttribute('disabled');
        } else {
          this.querySelector('#login-container #login').setAttribute('disabled', true);
        }
      };
      this.querySelector('#login-container email-input').onchange = loginFormChecker;
      this.querySelector('#login-container password-input').onchange = loginFormChecker;
      const loginBtn = this.querySelector('#login-container #login');
      if (loginBtn) {
        loginBtn.onclick = () => {
          this.checkLoginInput();
        };
      }
      const continueBtn = this.querySelector('#sign-up-container #continue');
      if (continueBtn) {
        continueBtn.onclick = () => {
          this.checkSignUpInput();
        };
      }
      const forgotBtn = this.querySelector('#login-container #forgotPassword');
      if (forgotBtn) {
        forgotBtn.onclick = () => {
          this.showForgotPasswordPrompt();
        };
      }
      const signupBtn = this.querySelector('#sign-up-container #submit');
      if (signupBtn) {
        signupBtn.onclick = () => {
          this.querySelector('#oauth2-container').style.display = 'none';
          this.showCreationForm();
          signupBtn.parentNode.style.display='none';
        };
      }
      this.querySelector('#noBtn').onclick = () => {
        this.dataset.state = this.previousState;
      };
      this.querySelector('.backBtn').onclick = () => {
        this.dataset.state = this.previousState;
      };
      this.querySelectorAll('.digitEntry').forEach(digiEle => {
        digiEle.onkeyup = comboEleKeyupHandle;
        digiEle.onfocus = comboEleFocusHandle;
      });
      this.querySelectorAll('#create-container a').forEach(anchor => {
        anchor.onclick = clickEvent => {
          clickEvent.preventDefault();
          if (this.dataset.state === 'login') {
            this.dataset.state = 'signup';
          } else {
            this.dataset.state = 'login';
          }
        };
      });
      this.querySelectorAll('.g-signin2').forEach(btn => {
        gapi.signin2.render(btn);
      });
      this.updateStyle();
    }
    updateStyle() {
      if (!this.isConnected) ;
    }
    setPrompt(params) {
      this.dataset.state = 'prompt';
      const prompt = this.querySelector('#prompt-container');
      const promptOptions = prompt.querySelector('#prompt-options-container');
      prompt.querySelector('#prompt-header').innerHTML = params.heading || 'Prompt Header';
      if (params.cancel && params.cancel === true) {
        this.querySelector('#noBtn').style.display='inline';
      } else {
        this.querySelector('#noBtn').style.display='none';
      }
      if (typeof params.content === 'string') {
        prompt.querySelector('#prompt-content').innerHTML = params.content || '';
      } else if (typeof params.content !== 'undefined') {
        prompt.querySelector('#prompt-content').appendChild(params.content);
      }
      promptOptions.innerHTML = '';
      if (!(params.options instanceof Array)) {
        return;
      }
      params.options.forEach(paramOption => {
        const btn = document.createElement('button');
        if (typeof paramOption.id === 'string') {
          btn.id = paramOption.id;
        }      let handle = paramOption.handle;
        if (typeof paramOption.handle !== 'function') {
          handle = () => undefined;
        }
        btn.innerHTML = paramOption.text;
        btn.onclick = handle;
        if (typeof paramOption.priority === 'string') {
          btn.classList.add(paramOption.priority);
        }
        promptOptions.appendChild(btn);
      });
    }
    setError(params) {
      const origState = this.dataset.state;
      this.dataset.state = 'error';
      const prompt = this.querySelector('#error-container');
      prompt.querySelector('#error-header').innerHTML = params.heading || 'Error Header';
      prompt.querySelector('#errBackBtn').onclick = () => {
        this.dataset.state = origState;
      };
      if (typeof params.content === 'string') {
        prompt.querySelector('#error-content').innerHTML = params.content || '';
      } else if (typeof params.content !== 'undefined') {
        prompt.querySelector('#error-content').appendChild(params.content);
      }
    }
    checkLoginInput() {
      const email = this.querySelector('#login-container email-input').value;
      const password = this.querySelector('#login-container password-input').value;
      this.AUTH_OBJ.userEmail = email;
      this.AUTH_OBJ.pass = password;
      if (typeof localStorage.lockOutUser !== 'string') {
        this.runAjaxLogin(email, password);
        return;
      }
      if (localStorage.lockOutUser == 'true') {
        if (timeToUnlockClient() != true) {
          return;
        }
        localStorage.lockOutUser = false;
      } else if (localStorage.lockOutUser != 'false') {
        return;
      }
      this.runAjaxLogin(email, password);
    }
    checkSignUpInput() {
      const email = this.querySelector('#sign-up-container email-input').value;
      const dob = this.querySelector('#sign-up-container dob-input').value;
      const password = this.querySelector('#sign-up-container password-input').value;
      this.AUTH_OBJ.userEmail = email;
      this.AUTH_OBJ.pass = password;
      this.AUTH_OBJ.dob = dob;
      let captcha = false;
      const captchaResponse = grecaptcha.getResponse();
      if ( captchaResponse.length !== 0 ) {
        captcha = true;
      }
      if (captcha == false) {
        this.querySelector('#checkBoxWrapper').classList.remove('hide');
        this.setError({
          heading: TEXT_ENUMS.header_funnyBad2,
          message: TEXT_ENUMS.solveCaptcha2,
        });
        return;
      }
      this.runAjaxSignUp(email, password, dob, captchaResponse);
    }
    showForgotPasswordPrompt() {
      const userEmail = this.querySelector('#login-container email-input').value;
      if (!userEmail || !this.querySelector('#login-container email-input').checkValidity()) {
        this.querySelector('#login-container email-input').setError(TEXT_ENUMS.error_invalidEmailRetry);
        return;
      }
      this.AUTH_OBJ.userEmail = userEmail;
      this.dataset.state = 'prompt';
      this.setPrompt({
        heading: `<link rel="stylesheet" href="/css/signupVerify.css" type="text/css">
      If <b>${userEmail}</b> is associated with an account, we will send it a new code via email.
      <br><br>How would you like to proceed?
      <br>
      <br>
      `,
        cancel: true,
        options: [{
          text: `Use previous code`,
          id: `haveIt`,
          handle: () => {
            this.populateAuthCombo(TEXT_ENUMS.header_sentEmailCode, () => {
              this.validateAuthInputReset();
            });
          },
        }, {
          text: `Send a new code`,
          id: 'resendIt',
          handle: () => {
            this.sendHelpEmailHelperNewCode(userEmail);
          },
        }],
      });
      return false;
    }
    validateAuthInputReset() {
      const userEmail = this.AUTH_OBJ.userEmail;
      const digit1 = this.querySelector('#validate-container #num1').value;
      const digit2 = this.querySelector('#validate-container #num2').value;
      const digit3 = this.querySelector('#validate-container #num3').value;
      const digit4 = this.querySelector('#validate-container #num4').value;
      const digit5 = this.querySelector('#validate-container #num5').value;
      const digit6 = this.querySelector('#validate-container #num6').value;
      if (digit1.match(/\d/ig) && digit2.match(/\d/ig) && digit3.match(/\d/ig) && digit4.match(/\d/ig) && digit5.match(/\d/ig) &&digit6.match(/\d/ig)) {
        this.validateReset(userEmail);
      } else {
        this.populateAuthCombo(TEXT_ENUMS.error_emailCodeFormat, () => {this.validateAuthInputReset();});
      }
    }
    async validateReset(userEmail) {
      const digit1 = this.querySelector('#validate-container #num1').value;
      const digit2 = this.querySelector('#validate-container #num2').value;
      const digit3 = this.querySelector('#validate-container #num3').value;
      const digit4 = this.querySelector('#validate-container #num4').value;
      const digit5 = this.querySelector('#validate-container #num5').value;
      const digit6 = this.querySelector('#validate-container #num6').value;
      const allDigits = ''.concat(digit1, digit2, digit3, digit4, digit5, digit6);
      const response = await fetch('/auth/forgotPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          auth_id: allDigits,
          user_email: userEmail,
        }),
      });
      if (response.ok) {
        const domEle = document.createElement('div');
        domEle.setAttribute('id', 'forgotpass-container');
        const returnBtn = document.createElement('button');
        returnBtn.innerText = 'Change Passwords';
        returnBtn.setAttribute('id', 'changePassword');
        returnBtn.setAttribute('disabled', 'true');
        returnBtn.onclick = () => {
          this.checkResetInput();
        };
        returnBtn.style.width = '200px';
        const psw = document.createElement('password-input');
        psw.setAttribute('showPasswrodStrength', 'true');
        psw.setAttribute('id', 'resetPassword');
        const formChecker = () => {
          if ( this.querySelector('#forgotpass-container password-input').valid ) {
            this.querySelector('#forgotpass-container #changePassword').removeAttribute('disabled');
          } else {
            this.querySelector('#forgotpass-container #changePassword').setAttribute('disabled', true);
          }
        };
        psw.onchange = formChecker;
        domEle.appendChild(psw);
        domEle.appendChild(returnBtn);
        this.setPrompt({
          heading: TEXT_ENUMS.enterNewPsw,
          cancel: true,
          content: domEle,
          options: [],
        });
      } else if (response.status == 403) {
        this.setPrompt({
          heading: TEXT_ENUMS.header_funnyBad5,
          content: TEXT_ENUMS.error_emailCodeZeroAttempts,
          options: [{
            text: TEXT_ENUMS.retry,
            handle: () => {
              this.runAjaxPasswordHelp(userEmail);
            },
          }],
        });
      } else if (response.status == 408) {
        this.showResendExpiredCode(userEmail, 'passwordReset');
      } else if (response.status == 401) {
        const dataJson = await response.json();
        this.populateAuthCombo(`You got the code wrong! You have ${dataJson.msg } left.<br>Please try again.`, () => {
          this.validateAuthInputReset();
        });
      } else if (response.status == 406) {
        this.setPrompt({
          heading: TEXT_ENUMS.header_funnyBad6,
          content: TEXT_ENUMS.error_emailCodeNoExist,
          options: [{
            text: 'Back',
            handle: showSignUp,
          }],
        });
      } else {
        this.setPrompt({
          heading: TEXT_ENUMS.header_funnyBad7,
          content: TEXT_ENUMS.error_generic4,
          options: [{
            text: 'Back',
            handle: showSignUp,
          }],
        });
      }
    }
    populateAuthCombo(headerStatement, action) {
      this.querySelector('#validation-header').innerHTML = headerStatement;
      this.dataset.state = 'validation';
      const okBtn = this.querySelector('#okBtn');
      okBtn.onclick = () => {
        action();
      };
    }
    sendHelpEmailHelperNewCode(userEmail) {
      this.setPrompt({
        heading: `Confirm you're not a robot`,
        content: `<div id="catpcha-messages">${TEXT_ENUMS.solveCaptcha}</div><br><div id='captcha_element_password' style="display:block;"></div>`,
        cancel: true,
        options: [],
      });
      let siteKey = '6LefBvUgAAAAACSuAb1mGet_tENyxoaX6_BisNQe';
      if (window.location.hostname === 'localhost') {
        siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
      }
      grecaptcha.render('captcha_element_password', {
        sitekey: siteKey,
        callback: () => {
          let captcha = false;
          const captchaResponse = grecaptcha.getResponse();
          if ( captchaResponse.length !== 0 ) {
            captcha = true;
          }
          if ( captcha ) {
            this.runAjaxPasswordHelp(userEmail, captchaResponse);
          } else {
            this.querySelector('#catpcha-messages').innerText = TEXT_ENUMS.badCaptchaAttempt;
          }
        },
      });
    }
    async runAjaxPasswordHelp(userEmail, captchaResponse) {
      this.querySelector('#login-options-container').style.display='block';
      const response = await fetch('/auth/help', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_email: userEmail,
          captchaTkn: captchaResponse,
        }),
      });
      if (response.status == 201 || response.status == 200) {
        this.populateAuthCombo(TEXT_ENUMS.header_sentEmailCode, () => {
          this.validateAuthInputReset();
        });
      } else if ( response.status == 403) {
        const dataJson = await response.json();
        this.setError({
          heading: TEXT_ENUMS.header_bad3,
          message: dataJson.msg,
        });
      } else if ( response.status == 401) {
        this.setError({
          heading: TEXT_ENUMS.header_emailNotFound,
          message: 'Oh no! We don\'t have that email on record, would you mind <a href=\'/sign-up.html\'>signing it up</a>?',
        });
      } else if ( response.status == 404) {
        this.setError({
          heading: TEXT_ENUMS.header_bad2,
          message: TEXT_ENUMS.error_containerRestarting,
        });
      } else {
        this.setError({
          heading: TEXT_ENUMS.header_bad3,
          message: TEXT_ENUMS.error_generic5,
        });
      }
    }
    async runAjaxLogin(userEmail, password) {
      const response = await fetch(
          '/auth/login',
          {
            method: 'POST',
            body: `user_email=${ userEmail }&psw=${ password}`,
          },
      );
      let jsonObj;
      switch (response.status) {
        case 200:
          const prmstr = window.location.search.substr(1);
          const searchParams = (prmstr !== null && prmstr != '') ? transformToAssocArray(prmstr) : {};
          if (typeof this.onAuth !== 'function') {
            location.href = searchParams.redirect?searchParams.redirect:'/';
          } else {
            document.querySelectorAll('.authentication').forEach(ele => {
              ele.setAttribute('data-authenticated', true);
            });
            document.body.setAttribute('data-authenticated', true);
            utilityObj.hideDialog();
            this.onAuth(jsonObj);
          }
          break;
        case 400:
          jsonObj = await response.json();
          document.querySelectorAll('[data-authenticated]').forEach(ele => {
            ele.removeAttribute('data-authenticated');
          });
          document.body.removeAttribute('data-authenticated', true);
          this.querySelector('#login-container password-input').setError(jsonObj.message);
          this.querySelector('#login-container #login').setAttribute('disabled', true);
          setNumAttempts(jsonObj.clientAttempts);
          break;
        case 401:
          jsonObj = await response.json();
          if (jsonObj.message === 'No Password Set') {
            this.setError({
              heading: TEXT_ENUMS.header_funnyBad1,
              message: TEXT_ENUMS.error_activatedNoPassword,
            });
          } else {
            this.querySelector('#login-container email-input').setError(jsonObj.message);
            this.querySelector('#login-container #login').setAttribute('disabled', true);
            setNumAttempts(jsonObj.clientAttempts);
          }
          break;
        case 403:
          this.showResendActivationCodePrompt();
          break;
        default:
          this.setError({
            heading: TEXT_ENUMS.header_funnyBad2,
            message: TEXT_ENUMS.error_generic1,
          });
          break;
      }
    }
    async runAjaxSignUp(userEmail, password, dob, captchaResponse) {
      const response = await fetch(
          '/auth/sign-up',
          {
            method: 'POST',
            body: `user_email=${ userEmail }&psw=${ password }&dob=${ dob }&captchaTkn=${captchaResponse}`,
          },
      );
      switch (response.status) {
        case 200:
          location.href = '/';
          break;
        case 201:
          this.querySelector('#signup-header').innerText = `Step 2 of 2`;
          this.populateAuthCombo(
              `Step 2 of 2<br>${TEXT_ENUMS.header_sentEmailCode}`,
              () => {
                this.validateAuthInputSignUp();
              },
          );
          break;
        case 302:
          const jsonObj = await response.json();
          location.href = jsonObj.message;
          break;
        case 400:
          this.checkIsActivated(userEmail);
          break;
        case 404:
          this.setError({
            heading: TEXT_ENUMS.header_bad2,
            message: TEXT_ENUMS.error_containerRestarting,
          });
          break;
        default:
          this.setError({
            heading: TEXT_ENUMS.header_bad3,
            message: TEXT_ENUMS.error_Weborg,
          });
          break;
      }
    }
    async checkIsActivated(userEmail) {
      const response = await fetch('/auth/activated', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_email: userEmail,
        }),
      });
      const fetchJson = await response.json();
      if (response.status == 200) {
        if (fetchJson.activated) {
          this.querySelector('#sign-up-container email-input').setError(fetchJson.msg);
        } else {
          this.handleAlreadyExistsError(TEXT_ENUMS.error_emailActivationNeeded);
        }
      } else if (response.status == 404) {
        this.querySelector('#sign-up-container email-input').setError(fetchJson.msg);
      } else if (response.status == 202) {
        this.querySelector('#sign-up-container email-input').setError(fetchJson.msg);
      } else {
        this.handleAlreadyExistsError(TEXT_ENUMS.error_generic1);
      }
    }
    handleAlreadyExistsError(signupErrorObject) {
      this.querySelector('#sign-up-container email-input').setError(signupErrorObject);
      this.setPrompt({
        heading: 'Account needs activation',
        cancel: true,
        options: [{
          text: `Use previous code`,
          id: `haveIt`,
          handle: () => {
            this.populateAuthCombo(TEXT_ENUMS.emailCodeReuse, () => {
              this.validateAuthInputSignUp();
            });
          },
        }, {
          text: `Send a new code`,
          id: 'resendIt',
          handle: () => {
            this.runAjaxResendActivationEmail();
          },
        }],
      });
    }
    showResendActivationCodePrompt() {
      this.setPrompt({
        heading: TEXT_ENUMS.header_accountActivation,
        content: TEXT_ENUMS.resendEmailCode,
        options: [
          {
            text: 'Enter previous code', handle: function() {
              this.populateAuthCombo(TEXT_ENUMS.emailCodeEntry, () => {
                this.validateAuthInputResend();
              });
            },
          },
          {
            text: 'Resend',
            handle: () => {this.runAjaxResendActivationEmail();},
          },
        ],
      });
    }
    showCreationForm() {
      this.querySelector('#sign-up-form-container').style.display = 'block';
      this.querySelector('#signup-header').innerText = `Step 1 of 2`;
      const formChecker = () => {
        const email = this.querySelector('#sign-up-form-container email-input').valid || false;
        let dob = this.querySelector('#sign-up-form-container dob-input').valid || false;
        dob &= this.querySelector('#sign-up-form-container dob-input').olderThan13;
        const password = this.querySelector('#sign-up-form-container password-input').valid || false;
        let captcha = false;
        const captchaResponse = grecaptcha.getResponse();
        if ( captchaResponse.length !== 0 ) {
          captcha = true;
        }
        if (email && dob && password && captcha) {
          this.querySelector('#sign-up-form-container #continue').removeAttribute('disabled');
        } else {
          this.querySelector('#sign-up-form-container #continue').setAttribute('disabled', true);
        }
      };
      let siteKey = '6LefBvUgAAAAACSuAb1mGet_tENyxoaX6_BisNQe';
      if (window.location.hostname === 'localhost') {
        siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
      }
      const captchaKey = 'signup_captcha_element';
      const captchaContainer = this.querySelector(`#${captchaKey}`);
      if (captchaContainer !== null && captchaContainer.children && captchaContainer.children.length > 0) ; else {
        grecaptcha.render(captchaContainer, {
          sitekey: siteKey,
          callback: formChecker,
        });
      }
      this.querySelector('#sign-up-form-container email-input').onchange = formChecker;
      this.querySelector('#sign-up-form-container dob-input').onchange = formChecker;
      this.querySelector('#sign-up-form-container password-input').onchange = formChecker;
    }
    validateAuthInputSignUp() {
      const userEmail = this.AUTH_OBJ.userEmail;
      const digit1 = this.querySelector('#validate-container #num1').value;
      const digit2 = this.querySelector('#validate-container #num2').value;
      const digit3 = this.querySelector('#validate-container #num3').value;
      const digit4 = this.querySelector('#validate-container #num4').value;
      const digit5 = this.querySelector('#validate-container #num5').value;
      const digit6 = this.querySelector('#validate-container #num6').value;
      if (digit1.match(/\d/ig) && digit2.match(/\d/ig) && digit3.match(/\d/ig) && digit4.match(/\d/ig) && digit5.match(/\d/ig) &&digit6.match(/\d/ig)) {
        this.validateSignUp(userEmail);
      } else {
        this.populateAuthCombo(TEXT_ENUMS.error_emailCodeFormat, () => {
          this.validateAuthInputSignUp();
        });
      }
    }
    async validateAuthInputResend() {
      const userEmail = this.AUTH_OBJ.userEmail;
      const digit1 = this.querySelector('#validate-container #num1').value;
      const digit2 = this.querySelector('#validate-container #num2').value;
      const digit3 = this.querySelector('#validate-container #num3').value;
      const digit4 = this.querySelector('#validate-container #num4').value;
      const digit5 = this.querySelector('#validate-container #num5').value;
      const digit6 = this.querySelector('#validate-container #num6').value;
      const allDigits = ''.concat(digit1, digit2, digit3, digit4, digit5, digit6);
      if (digit1.match(/\d/ig) && digit2.match(/\d/ig) && digit3.match(/\d/ig) && digit4.match(/\d/ig) && digit5.match(/\d/ig) &&digit6.match(/\d/ig) ) {
        const response = await fetch('/auth/resendAuth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            auth_id: allDigits,
            user_email: userEmail,
          }),
        });
        const dataJson = await response.json();
        if (response.status == 200) {
          this.setPrompt({
            heading: `Welcome to TalkDust! You've been successfully authenticated!<br>`,
            cancel: false,
            options: [{
              text: `Homepage`,
              id: `homepage`,
              handle: () => {
                this.redirectHome();
              },
            }],
          });
        } else if (response.status == 408) {
          this.showResendExpiredCode(userEmail, 'activation');
        } else if (response.status == 401) {
          const attempts = JSON.stringify(dataJson).match(/\d/);
          this.querySelector('#validation-header').innerHTML = `You got the code wrong! You have <span style='color:red'>${attempts }</span> left.<br>Please try again.`;
        } else if (
          response.status == 403 ||
          response.status == 418 ||
          response.status == 404 ||
          response.status == 406
        ) {
          this.setPrompt({
            heading: TEXT_ENUMS.error_emailCodeZeroAttempts,
            cancel: false,
            options: [{
              text: `Start Over`,
              id: `again`,
              handle: () => {
                this.dataset.state = this.previousState;
              },
            }],
          });
        }
      } else {
        this.populateAuthCombo(TEXT_ENUMS.error_emailCodeFormat, () => {
          this.validateAuthInputSignUp();
        });
      }
    }
    async validateSignUp(userEmail) {
      const digit1 = this.querySelector('#validate-container #num1').value;
      const digit2 = this.querySelector('#validate-container #num2').value;
      const digit3 = this.querySelector('#validate-container #num3').value;
      const digit4 = this.querySelector('#validate-container #num4').value;
      const digit5 = this.querySelector('#validate-container #num5').value;
      const digit6 = this.querySelector('#validate-container #num6').value;
      const allDigits = ''.concat(digit1, digit2, digit3, digit4, digit5, digit6);
      const updatesEle = this.querySelector('#emailUpdates');
      const updates = updatesEle ? updatesEle.checked : false;
      const response = await fetch('/auth/signUpAuth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          auth_id: allDigits,
          user_email: userEmail,
          updates: updates,
        }),
      });
      const dataJson = await response.json();
      if (response.status == 200) {
        this.setPrompt({
          heading: TEXT_ENUMS.accountCreatedWelcome,
          cancel: false,
          options: [{
            text: `Homepage`,
            id: `homepage`,
            handle: () => {
              this.redirectHome();
            },
          }],
        });
      } else if (response.status == 403) {
        this.querySelector('#signup-header').innerHTML = TEXT_ENUMS.error_emailCodeZeroAttempts;
      } else if (response.status == 408) {
        this.showResendExpiredCode(userEmail, 'activation');
      } else if (response.status == 401) {
        const attempts = JSON.stringify(dataJson).match(/\d/);
        this.querySelector('#validation-header').innerHTML = `You got the code wrong! You have <span style='color:red'>${attempts }</span> left.<br>Please try again.`;
      } else if (response.status == 404) {
        this.showResendActivationCodePrompt();
      } else if (response.status == 500) {
        this.showResendActivationCodePrompt();
      } else {
        this.querySelector('#signup-header').innerHTML = TEXT_ENUMS.error_generic4;
      }
    }
    showResendExpiredCode(email, scope) {
      let handle;
      if (scope === 'passwordReset') {
        handle = () => {
          this.dataset.state = 'login';
          this.sendHelpEmailHelperNewCode(email);
        };
      } else if (scope === 'activation') {
        handle = () => {
          this.dataset.state = 'signup';
          this.runAjaxResendActivationEmail();
        };
      } else {
        conesole.warn('Missed a showResendExpiredCode call -- need to know if coming from login or signup essentially');
      }
      this.setPrompt({
        heading: TEXT_ENUMS.header_emailCodeExpired,
        content: TEXT_ENUMS.error_emailCodeExpired,
        options: [
          {
            text: 'Resend!',
            id: 'resend',
            handle: handle,
          },
        ],
      });
    }
    redirectHome() {
      const email = this.AUTH_OBJ.userEmail;
      const password = this.AUTH_OBJ.pass;
      this.runAjaxLogin(email, password);
    }
    async runAjaxResendActivationEmail() {
      const userEmail = this.AUTH_OBJ.userEmail;
      const response = await fetch('/auth/resend', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_email: userEmail,
        }),
      });
      if (response.ok) {
        this.populateAuthCombo(TEXT_ENUMS.header_sentEmailCode, () => {
          this.validateAuthInputResend();
        });
      } else {
        this.querySelector('#signup-header').innerHTML = TEXT_ENUMS.error_generic2;
      }
    }
    checkResetInput() {
      const email = this.AUTH_OBJ.userEmail;
      const password = this.querySelector('#resetPassword').value;
      this.AUTH_OBJ.pass = password;
      if (typeof localStorage.lockOutUser === 'string') {
        if (localStorage.lockOutUser == 'true') {
          if (timeToUnlockClient() == true) {
            localStorage.lockOutUser = false;
            this.changePassword(email, password);
          }
        } else if (localStorage.lockOutUser == 'false') {
          this.changePassword(email, password);
        }
      } else {
        this.changePassword(email, password);
      }
    }
    async changePassword(userEmail, password) {
      const response = await fetch('/auth/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_email: userEmail,
          password: password,
        }),
      });
      if (response.ok) {
        this.setPrompt({
          heading: TEXT_ENUMS.passwordUpdated,
          cancel: false,
          options: [{
            text: `Log in`,
            id: `resetLogin`,
            handle: () => {
              this.redirectHome();
            },
          }],
        });
      } else if (response.status == 406) {
        this.setPrompt({
          heading: TEXT_ENUMS.header_funnyBad6,
          content: TEXT_ENUMS.error_noMatchingEmail,
          cancel: false,
          options: [{
            text: 'Back',
            handle: showSignUp,
          }],
        });
      } else {
        this.setPrompt({
          heading: TEXT_ENUMS.header_funnyBad7,
          content: TEXT_ENUMS.error_generic4,
          cancel: false,
          options: [{
            text: 'Back',
            handle: showSignUp,
          }],
        });
      }
    }
    setOnAuthFunc(onAuthFunc) {
      this.onAuth = onAuthFunc;
    }
  }
  function setNumAttempts(attempts) {
    if (attempts >=5) {
      beginLockout(attempts);
    }
  }
  function beginLockout(attempts) {
    if (localStorage.loginAttempts) {
      localStorage.loginAttempts = (attempts - 5);
    } else {
      localStorage.loginAttempts = 1;
    }
    localStorage.lockOutUser = true;
    localStorage.timeOfUserLock = Date.now();
    localStorage.requiredWaitTime = ((localStorage.loginAttempts * localStorage.loginAttempts) * 1000);
    timeToUnlockClient();
  }
  function timeToUnlockClient() {
    const timeLeft = getTimeLeft();
    if (timeLeft.minutes <= 0 && timeLeft.seconds <= 0) {
      document.getElementById('error-display').classList.add('hide');
      return true;
    }
    document.getElementById('error-display').classList.remove('hide');
    document.getElementById('error-display').innerHTML = `
    Too many failed attempts<br>Please wait: ${ timeLeft.hours } hours, ${ timeLeft.minutes } minutes, ${ timeLeft.seconds } seconds to try again.
  `;
    setTimeout(timeToUnlockClient, 250);
    return false;
  }
  function getTimeLeft() {
    const currentTime = new Date();
    const userWaitTime = Math.abs(parseInt(localStorage.timeOfUserLock) - currentTime);
    let timeLeftInMilliseconds = parseInt(localStorage.getItem('requiredWaitTime')) - userWaitTime;
    const hours = getHours(timeLeftInMilliseconds);
    timeLeftInMilliseconds = timeLeftInMilliseconds - (hours * 3600000);
    const minutes = getMinutes(timeLeftInMilliseconds);
    timeLeftInMilliseconds = timeLeftInMilliseconds - (minutes * 60000);
    const seconds = getSeconds(timeLeftInMilliseconds);
    return {hours: hours, minutes: minutes, seconds: seconds};
  }
  function comboEleKeyupHandle(event) {
    if (event.keyCode === 16 || event.keyCode == 9 || event.keyCode == 224 || event.keyCode == 18 || event.keyCode == 17) {
      return;
    }
    if ( (event.keyCode === 8 || event.keyCode === 37) && event.target.previousElementSibling && event.target.previousElementSibling.tagName === 'INPUT' ) {
      event.target.previousElementSibling.select();
    } else if (event.keyCode !== 8 && event.target.nextElementSibling && event.target.nextElementSibling.select) {
      event.target.nextElementSibling.select();
    }
    if ( event.target.value.length > 1 ) {
      _splitNumber(e);
    }
    const okBtn = document.querySelector('#validate-container #okBtn');
    if (!document.querySelector('#validate-container #num1').value) {
      okBtn.setAttribute('disabled', true);
      return;
    }
    if (!document.querySelector('#validate-container #num2').value) {
      okBtn.setAttribute('disabled', true);
      return;
    }
    if (!document.querySelector('#validate-container #num3').value) {
      okBtn.setAttribute('disabled', true);
      return;
    }
    if (!document.querySelector('#validate-container #num4').value) {
      okBtn.setAttribute('disabled', true);
      return;
    }
    if (!document.querySelector('#validate-container #num5').value) {
      okBtn.setAttribute('disabled', true);
      return;
    }
    if (!document.querySelector('#validate-container #num6').value) {
      okBtn.setAttribute('disabled', true);
      return;
    }
    okBtn.removeAttribute('disabled');
  }function comboEleFocusHandle(event) {
    if ( event.target.id === 'num1' ) return;
    if ( event.target.parentNode.querySelector('#num1').value == '' ) {
      event.target.parentNode.querySelector('#num1').focus();
    }
    if ( event.target.previousElementSibling.value == '' ) {
      event.target.previousElementSibling.focus();
    }
  }function _splitNumber(event) {
    let digitString = event.data || event.target.value;
    if ( ! digitString ) return;
    if ( digitString.length === 1 ) return;
    const currentEle = event.target;
    while (currentEle.nextElementSibling && digitString.length) {
      currentEle.value = digitString[0];
      digitString = digitString.substring(1);
    }
  }customElements.define('auth-component', AuthElement);

  exports.DobInput = DobInput;
  exports.EmailInput = EmailInput;
  exports.PasswordInput = PasswordInput;
  exports.default = AuthElement;

  Object.defineProperty(exports, '__esModule', { value: true });

  return exports;

})({});
