import AuthComponent from '@talkdust/auth-component';

const extraEmailCheck = false;
let extraPasswordCheck = false;
const extraPasswordMatchCheck = false;

const AUTH_OBJ = {};
const authObj = {};
window.authObj = authObj;

/**
 * This function solely checks passwords in the 'forgot my password' modal
 * Potentially combine the CheckInput() with access to a bool to see if its signup or Reset, and do the appropriate function combo
 */
function checkResetInput() {
  const email = AUTH_OBJ.userEmail;// document.getElementById('user_email').value;
  const password = document.querySelector('#resetPassword').value;
  AUTH_OBJ.pass = password;

  if (typeof localStorage.lockOutUser === 'string') {
    if (localStorage.lockOutUser == 'true') {
      if (timeToUnlockClient() == true) {
        localStorage.lockOutUser = false;
        changePassword(email, password);
      }
    } else if (localStorage.lockOutUser == 'false') {
      changePassword(email, password);
    }
  } else {
    changePassword(email, password);
  }
}


// check to see if we have 5 attempts logged for the client.
/**
@param {number} attempts
*/
function setNumAttempts(attempts) {
  if (attempts >=5) {
    beginLockout(attempts);
  }
}

// create time lockouts for every 5+ failed attempts
/**
@param {number} attempts
*/
function beginLockout(attempts) {
  if (localStorage.loginAttempts) {
    localStorage.loginAttempts = (attempts - 5);
  } else {
    localStorage.loginAttempts = 1;
  }

  localStorage.lockOutUser = true;
  localStorage.timeOfUserLock = Date.now();
  localStorage.requiredWaitTime = ((localStorage.loginAttempts * localStorage.loginAttempts) * 1000); // milliseconds.
  timeToUnlockClient();
}

// return a true or false. Notifies user of n hours and m minutes and x seconds.
/**
@return {boolean}
*/
function timeToUnlockClient() {
  const timeLeft = getTimeLeft();

  // if we have waited long enough...
  if (timeLeft.minutes <= 0 && timeLeft.seconds <= 0) {
    document.getElementById('error-display').classList.add('hide');
    return true;
  }
  document.getElementById('error-display').classList.remove('hide');
  document.getElementById('error-display').innerHTML = `
    Too many failed attempts<br>Please wait: ${ timeLeft.hours } hours, ${ timeLeft.minutes } minutes, ${ timeLeft.seconds } seconds to try again.
  `;
  setTimeout(timeToUnlockClient, 250);

  return false;
}

/**
@return {object}
*/
function getTimeLeft() {
  const currentTime = new Date();
  const userWaitTime = Math.abs(parseInt(localStorage.timeOfUserLock) - currentTime);
  let timeLeftInMilliseconds = parseInt(localStorage.getItem('requiredWaitTime')) - userWaitTime;
  const hours = getHours(timeLeftInMilliseconds);
  timeLeftInMilliseconds = timeLeftInMilliseconds - (hours * 3600000);
  const minutes = getMinutes(timeLeftInMilliseconds);
  timeLeftInMilliseconds = timeLeftInMilliseconds - (minutes * 60000);
  const seconds = getSeconds(timeLeftInMilliseconds);
  timeLeftInMilliseconds = timeLeftInMilliseconds - (seconds * 1000);

  return {hours: hours, minutes: minutes, seconds: seconds};
}


// return a JSON with 2 attributes: passed: true/false, message: password/user_email issue.
/**
@param {string} clientEmailFormat
@param {string} clientPassword
@return {boolean}
*/
function basicAuth(clientEmailFormat, clientPassword) {
  let authentication = {passed: false};
  const auth1 = checkEmailInput(clientEmailFormat);
  const auth2 = checkPasswordInput(clientPassword);

  if (auth1 === true && auth2 === true) {
    authentication = {passed: true, message: 'login successful'};
  } else {
    return (auth1 === true)?(auth2):(auth1);
  }

  return authentication;
}

/**
@param {string} clientPassword
*/
function checkPasswordInputOptional(clientPassword) {
  if (extraPasswordCheck) {
    checkPasswordInput(clientPassword);
  }
}

/**
@param {string} clientPassword
@return {boolean}
*/
function checkPasswordInput(clientPassword) {
  extraPasswordCheck = true;
  if (typeof clientPassword === 'string' && clientPassword.length < 3) {
    const authentication = {passed: false, message: TEXT_ENUMS.passwordLengthRequired, emailPassed: true, passwordPassed: false};
    incorrectPasswordAlertReset(authentication.message);
    return authentication;
  }
  // if (document.getElementById('psw') !== null) { // password popup exists
  //   document.getElementById('psw').classList.remove('highlightTextbox');
  //   document.getElementById('psw_error').classList.add('hide');
  // } else {
  document.getElementById('psw').classList.remove('highlightTextbox');
  document.getElementById('psw_error').classList.add('hide');
  // }

  return true;
}

/**
TD callback for login auth
*/
function facebookLoginCallback() {
  // do special things here.. but first acknkowledge it was clicked!
  // console.log('Facebook Login was clicked');
  FB.api('/me?fields=id,name,email,picture', function(response) {
    if (response.error === null || typeof response.error === 'undefined') {
      // console.log(`Successful login for: ${ response.name}`);
      // we need to hit a different rest endpoint, and pass along the JSON we just obtained
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.open('POST', '/auth/fblogin', true);
      xmlhttp.onreadystatechange=function() {
        if (xmlhttp.readyState == 4 && xmlhttp.status === 200) {
          const jsonObj = JSON.parse(xmlhttp.response);
          const searchParams = getSearchParameters();
          shellObj.setCookie('userToken', jsonObj.token);
          shellObj.setCookie('user', jsonObj.id);
          shellObj.setCookie('alias', jsonObj.alias);
          shellObj.setCookie('facebookLinkID', jsonObj.fbId);
          sessionStorage.setItem('profilePic', JSON.stringify(response.picture));
          location.href = searchParams.redirect?searchParams.redirect:'/';
        } else if (xmlhttp.readyState === 4) {
          showErrorDialog({heading: TEXT_ENUMS.header_funnyBad1, message: TEXT_ENUMS.error_generic1}, () => undefined);
        }
      };
      xmlhttp.send(`user_email=${ response.email }&fbId=${ response.id }&name=${ response.name}`);
    } else {
      console.error(`Faceook is upset: ${JSON.stringify(response)}`);
      showErrorDialog({heading: TEXT_ENUMS.header_funnyBad2, message: TEXT_ENUMS.error_generic2}, () => undefined);
    }
  });
}
window.facebookLoginCallback = facebookLoginCallback;

/**
TODO eval if still used
@param {number} milliseconds
@return {number}
*/
function getHours(milliseconds) {
  return Math.trunc(milliseconds/3600000);
}

/**
TODO eval if still used
@param {number} milliseconds
@return {number}
*/
function getMinutes(milliseconds) {
  return Math.trunc(milliseconds/60000);
}

/**
TODO eval if still used
@param {number} milliseconds
@return {number}
*/
function getSeconds(milliseconds) {
  return Math.trunc(milliseconds/1000);
}

/**
TODO eval if still used
@return {object}
*/
function getSearchParameters() {
  const prmstr = window.location.search.substr(1);
  return prmstr !== null && prmstr != '' ? transformToAssocArray(prmstr) : {};
}

/**
TODO eval if still used
@param {string} prmstr
@return {string}
*/
function transformToAssocArray( prmstr ) {
  const params = {};
  const prmarr = prmstr.split('&');
  for ( let i = 0; i < prmarr.length; i++) {
    const tmparr = prmarr[i].split('=');
    params[tmparr[0]] = tmparr[1];
  }
  return params;
}

/**
@param {string} userEmail
@param {string} password
*/
async function changePassword(userEmail, password) {
  const response = await fetch('/auth/reset', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_email: userEmail,
      password: password,
    }),
  });

  if (response.ok) {
    const loginHeader = document.querySelector('#login-messages');

    document.getElementById('login-messages').innerText = TEXT_ENUMS.passwordUpdated;
    const brEle = document.createElement('br');
    document.getElementById('login-container').appendChild(brEle);
    const returnBtn = document.createElement('button');
    returnBtn.innerText = 'Log in!';
    returnBtn.setAttribute('id', 'resetLogin');
    returnBtn.onclick = redirectHome;
    returnBtn.style.width = '200px';
    document.getElementById('login-dynamic-content').innerHTML = '';
    document.getElementById('login-dynamic-content').appendChild(returnBtn);
  } else if (response.status == 406) {
    utilityObj.showPromptDialog({
      heading: TEXT_ENUMS.header_funnyBad6,
      message: TEXT_ENUMS.error_noMatchingEmail,
      choices: [{
        wording: 'Back',
        handle: showSignUp,
      }],
    });
  } else {
    utilityObj.showPromptDialog({
      heading: TEXT_ENUMS.header_funnyBad7,
      message: TEXT_ENUMS.error_generic4,
      choices: [{
        wording: 'Back',
        handle: showSignUp,
      }],
    });
  }
}

/**
 * This function redirects the user on completion
 */
function redirectLogin() {
  window.location.href = `/login.html`;
}

/**
 * This function redirects the user on completion
 * It also logs the user in to prevent further redirection utilizing runAjax()
 */
function redirectHome() {
  let password= '';
  const email = AUTH_OBJ.userEmail;// document.getElementById('user_email').value;
  // if (document.getElementById('psw') !== null) { // password popup exists
  // password = document.getElementById('psw').value;
  // } else { // password popup doesnt exist
  password = AUTH_OBJ.pass;// document.getElementById('psw').value;
  // }

  runAjax(email, password);
  // window.location.href = `/`;
}

/**
 * This function redirects the user on completion
 */
function redirectSignUp() {
  window.location.href = `/sign-up.html`;
}


/**
 * Check DOM for passwords matching
 * @return {boolean}
 */
function clientPasswordsMatch() {
  if (document.getElementById('login-messages') !== null) { // if the 2nd password doesnt exist, you are on login & trying to reset password
    const passwords = document.getElementsByClassName('passwords');
    const initialPassword = passwords[0].value;
    const retypedPassword = document.getElementById('re-psw').value;
    if (initialPassword == retypedPassword) {
      return true;
    }
    return false;
  }
  const initialPassword = document.getElementById('psw').value;
  const retypedPassword = document.getElementById('re-psw').value;
  if (initialPassword == retypedPassword) {
    return true;
  }
  return false;
}

/**
 * Check DOM for password strength
 @param {Object} event
 */
function passwordStrength(event) {
  const psw = event.target.value;

  if (psw.length > 0) {
    event.target.parentNode.querySelector('#passContainer').style.display = 'block';
    // document.getElementById('passContainer').style.display = 'block';
  } else {
    event.target.parentNode.querySelector('#passContainer').style.display = 'none';
    // document.getElementById('passContainer').style.display = 'none';
  }
  const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
  const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');

  let msg = '';

  if (strongPassword.test(psw)) {
    msg = 'strong';
  } else if (mediumPassword.test(psw)) {
    msg = 'medium';
  } else {
    msg = 'weak';
  }

  event.target.parentNode.querySelector('#passStrWord').innerHTML = msg.charAt(0).toUpperCase() + msg.slice(1);
  event.target.parentNode.querySelector('#passStrWord').className = msg;
  event.target.parentNode.querySelector('#passStrBar').className = msg;
}

/**
 * Submit login data from successful Facebook Login
 */
function facebookLoginCallbackSignUp() {
  // do special things here.. but first acknkowledge it was clicked!
  FB.api('/me?fields=id,name,email,picture', function(response) {
    // we need to hit a different rest endpoint, and pass along the JSON we just obtained
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', '/auth/fblogin', true);
    xmlhttp.onreadystatechange=function() {
      if (xmlhttp.readyState == 4 && xmlhttp.status === 200) {
        const jsonObj = JSON.parse(xmlhttp.response);
        shellObj.setCookie('userToken', jsonObj.token);
        shellObj.setCookie('alias', jsonObj.alias);
        shellObj.setCookie('user', jsonObj.id);
        shellObj.setCookie('facebookLinkID', jsonObj.fbId);
        sessionStorage.setItem('profilePic', JSON.stringify(response.picture));
        location.href = '/';
      } else if (xmlhttp.readyState === 4) {
        showErrorDialog({heading: TEXT_ENUMS.header_funnyBad1, message: TEXT_ENUMS.error_generic1});
      }
    };
    xmlhttp.send(`user_email=${ response.email }&fbId=${ response.id }&name=${ response.name}`);
  });
}
window.facebookLoginCallbackSignUp = facebookLoginCallbackSignUp;

/**
 * Check DOM for equality with passwords
 * @return {boolean} true || false dependeing on if DOM matches
 */
function clientPasswordsMatchSignUp() {
  const initialPassword = document.getElementById('psw').value;
  const retypedPassword = document.getElementById('re-psw').value;
  return initialPassword === retypedPassword;
}

/**
@return {Promise}
*/
function getProfilePicture() {
  return new Promise((resolve, reject) => {
    // TODO replace userID with something specific to an OAuth2 token
    // TODO replace xmlhttp with fetch
    if (shellObj.getCookie('facebookLinkID')) {
      profilePic = shellObj.getCookie('facebookLinkID')?`https://graph.facebook.com/${shellObj.getCookie('facebookLinkID')}/picture?width=100&height=100`:'';
      resolve({
        picURL: profilePic,
      });
    } else if (shellObj.getCookie('googlePicURL')) {
      profilePic = shellObj.getCookie('googlePicURL')?shellObj.getCookie('googlePicURL'):'';
      resolve({
        picURL: profilePic,
      });
    } else {
      const xmlhttp2 = new XMLHttpRequest();
      xmlhttp2.open('GET', `/auth/profile_pic`, true); // ?name=${attachmentName}
      xmlhttp2.onreadystatechange=function() {
        if (xmlhttp2.readyState == 4 && xmlhttp2.status === 200) {
          const jsonObj = JSON.parse(xmlhttp2.response);
          if (typeof jsonObj.imgb64 !== 'undefined' && jsonObj.imgb64 !== null) {
            // picIMG =jsonObj.imgb64;
            resolve({
              picIMG: jsonObj.imgb64,
            });
          } else {
            resolve({
              picURL: '',
            });
          }
        } else if (xmlhttp2.readyState === 4) {
          resolve({
            picURL: '',
          });
        }
      };
      xmlhttp2.onerror=function(profilePicError) {
        console.error(profilePicError);
        resolve({
          picURL: '',
        });
      };
      xmlhttp2.send();
    }
  });
}
authObj.getProfilePicture = getProfilePicture;
